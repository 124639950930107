<template>
  <div>
    <div class="jumbotron-wrapper color">
      <div class="jumbotron container-lg px-4">
        <div class="animation cover-text mt-5">
          <div class="row gx-5">
            <header class="mb-4">
              <p class="fs-1 fw-bolder mb-1">
                非凸科技受邀出席东北证券京津冀私募圈俱乐部线下交流会
              </p>
              <div class="fst-italic mb-2">2023年3月30日</div>
              <a
                class="badge bg-secondary text-decoration-none link-light"
                href="#!"
                >新闻</a
              >
              <a
                class="ms-1 badge bg-secondary text-decoration-none link-light"
                href="#!"
                >东北证券</a
              >
            </header>
          </div>
        </div>
      </div>
    </div>
    <div class="content p-auto">
      <section class="py-5">
        <div class="container-lg px-4">
          <div class="row">
            <div class="col-lg-3">
              <div class="d-flex align-items-center mt-lg-5 mb-4">
                <img
                  class="img-fluid rounded-circle logo_50"
                  src="../../assets/img/logo/logo_50.jpeg"
                  alt="非凸科技"
                />
                <div class="ms-3">
                  <div class="fw-bold">非凸新闻</div>
                  <div class="text-muted">Non-convex News</div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <article class="lh-lg">
                <section class="mb-5">
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/31/01-主题.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    近日，非凸科技受邀出席东北证券在北京举办的京津冀私募圈俱乐部线下交流会，深度分享了非凸算法及其应用实践，并与管理人、资方等在场嘉宾共同探讨了行业发展新趋势及算法合作新模式。
                  </p>
                  <p>
                    金融与算法的深度融合，已成为行业发展新趋势。在主题分享中，非凸科技机构业务经理周升从算法使用场景、内在逻辑、绩效表现、技术优势及算法赋能等角度，为与会嘉宾详细阐述了非凸算法助力机构业务的优越性。
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/31/02-路演.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    东北证券正着力发展机构业务，提升创新技术能力，为投资者提供交易服务全生命周期的专业化服务。对此，非凸科技基于机器学习功底、数据挖掘能力及策略研发实力，将全力协助东北证券提升算法赋能机构客户的效力。目前，非凸最新算法已上线东北证券，凭借优异绩效、极致性能、精细服务等表现，获得券商及管理人的高度认可与信任。
                  </p>
                  <p>
                    非凸科技积极应用机器学习、深度学习等人工智能技术，以实现强大信息搜集与处理能力，及时快速跟踪市场变化，不断发现人工较难以捕捉的交易机会，更多获取交易执行环节的超额收益。非凸智能算法可以满足客户对交易执行的各种场景需求，且大幅跑赢传统算法，战胜市场均价。2023开年以来，这一算法已成功上线多家头部券商，绩效表现明显优于其他厂商，平均超额在3-4bp。
                  </p>
                  <p>
                    优秀的业绩离不开出色的团队，金融素养和工程实力过硬，能够快速实现开发部署。他们大多来自海内外知名院校，获得ACM World Final 等国际竞赛奖项，拥有强大的编程技能和敏锐的观察力和行动力；除此之外，非凸科技通过每年千万级的算力投入，为其研究提供坚实的条件支持。目前，非凸团队规模已超百人，年轻又富有活力，在探索前沿算法的道路上严谨且细致。
                  </p>
                  <p>
                    除此之外，非凸科技有着高度配合的专业服务团队，涵盖售前、开发、测试、部署、售后等全方面服务，支持7*24小时线上/线下沟通，可以协助东北证券打造覆盖机构全生命周期的服务生态圈。在创新业务模式探索中，非凸科技还可安排开发人员到客户现场联合开发，加快创新业务落地进程。
                  </p>
                  <p>
                    2022年，非凸科技与东北证券开启了初次合作，作为技术支持战略合作单位，助力第一届“金盏杯”私募实盘大赛顺利开展。双方在算法策略、产品服务、品牌推广等方面已展开深度合作，同时将在人工智能领域加大合作力度，寻求技术突破，共同围绕机构客户全生命周期业务需求而努力。
                  </p>
                  <p>
                    人工智能和大数据等创新技术在金融领域越来越深入，技术与业务的融合趋势也越来越显著。在后续合作中，非凸科技将始终做东北证券的好伙伴，发挥自身优势，共同技术创新，实现共享互利、强强联合。
                  </p>
                  <p>
                    未来，非凸科技也将继续以客户为中心，快速响应政策及市场变化，高效敏捷支持业务需求，为行业高速发展贡献科技力量。
                  </p>
                  <p>
                    欢迎咨询东北证券营业部或非凸科技商务部，申请试用！
                  </p>
                </section>
              </article>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "News31",
};
</script>

<style></style>
